import React from "react";
import classNames from "classnames";
import { Breadcrumbs, ModuleList } from "@molecules";
import { Container, Text, Image } from "@atoms";

const Overview = ({
  audiences,
  className: _className,
  copy,
  crumbs,
  heading,
  image,
  modules,
}) => {
  return (
    <section className={classNames("py-10 sm:py-20", _className)}>
      <Container>
        <div className="flex flex-col gap-10 sm:gap-20">
          {/* hero */}
          <div className="flex flex-wrap items-start gap-10">
            <div className="flex flex-grow flex-col gap-10 md:flex-1">
              <Breadcrumbs color="default" crumbs={crumbs} />
              <div className="flex flex-col gap-5">
                {!!audiences?.length && (
                  <Text className="text-sm uppercase leading-normal text-teal">
                    {audiences.map(a => a.title).join(", ")}
                  </Text>
                )}
                <Text variant="h1">{heading}</Text>
              </div>
            </div>
            {image && (
              <Image
                image={image}
                aspectRatio={[1, 1]}
                className="w-40 rounded-full md:w-[18rem]"
              />
            )}
          </div>
          {/* copy + sidebar */}
          <div className="flex flex-wrap gap-10">
            <div className="flex-grow sm:flex-1">
              <Text richText>{copy}</Text>
            </div>
            <div className="w-full md:w-[18rem]">
              <ModuleList modules={modules} />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

Overview.defaultProps = {};

export default Overview;
